#about-me-paragraph-wrapper
{
    padding-right: 0rem;
    padding-left: 0rem;
    padding-bottom: 3.5rem;
}

#about-wrapper 
{
    min-height: calc( 100% + 40px );
}