html {
    /*  position: relative;*/
    top: 0;
    font-size: 14px;
    /* font-size: 1rem; */
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

body {
    height: inherit;
    min-height: inherit;
    overflow-x: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

nav {
    /* height: 7vh; */
}

main {
    /* top: 7vh; */
    height: 90vh;
    min-height: 90vh;
    /* max-height: 90vh; */
}

footer {
    /* font-size: 10px; */
    margin-top: 5px;
    height: 3vh;
}

.nav-link {
    font-size: 1.25rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.text-sm-caps {
    font-variant-caps: small-caps;
}

.text-lg-caps {
    font-variant-caps: petite-caps;
}


.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
  }

main p 
{
    font-size: 1.6rem;
}

* {
    box-sizing: border-box;
}

/* @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  } */
  