:root 
{
    --transition-time-start: 500ms;
    --transition-time-end: 1000ms;
}

.font-size-150 {
    font-size: 1.5rem !important;
}

/* lg */
@media (min-width: 992px) {
    .font-size-lg-100 {
        font-size: 1.25rem !important;
    }
}

/* sm */
@media (min-width: 576px) {
    /* .font-size-sm-150 {
        font-size: 1.5rem !important;
    } */
}



.bg-lighter-dark {
    background-color:  rgb( 50, 50, 50 ) !important;
}

.nav-link 
{
    color: white !important;
}

.nav-link:hover {
    color: var( --bs-gray-400 ) !important;
} 

.navbar 
{
    opacity: 0.9;
    transition:
        opacity var( --transition-time-end, 1s ),
        display var( --transition-time-end, 1s );
}

.navbar:hover 
{
    opacity: 1;
    transition:
        opacity var( --transition-time-start, 1s ),
        display var( --transition-time-start, 1s );
}

.rounded-25 
{
    border-radius: 25px;
}

.rounded-20 
{
    border-radius: 20px;
}

.rounded-15 
{
    border-radius: 15px;
}

.rounded-10 
{
    border-radius: 15px;
}

.rounded-top-25
{
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.rounded-top-20
{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.rounded-top-15
{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.rounded-top-10
{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}